import React from 'react'

const Footer = () => {
    return (
        <>
            <footer className="footer">
                <div className="footer-container">
                    <div className="footer-logo">
                        <img src="assets/images/logo.png" alt="" />
                        <h3 id="tagline">Electrical, Heating and Cooling Inc.</h3>
                    </div>
                    <div className="footer-details">
                        <div className="details-contact foot-all">
                            <p><i style={{marginRight: 4}} class="fa-solid fa-phone"></i><a href="tel:7169374628" style={{color: 'white', textDecoration: 'none', fontWeight: 'normal'}}> (716) 937-4628</a></p>
                            <p><i style={{marginRight: 4}} class="fa-solid fa-envelope"></i><a href="mailto:ljl@ljlehvac.com" style={{color: 'white', textDecoration: 'none', fontWeight: 'normal'}}> ljl@ljlehvac.com</a></p>
                        </div>
                        <div className="business-hours foot-all">
                            <h3>Business Hours</h3>
                            <p>Mon-Fri : 8:00am -5:00pm</p>
                            <p>Sat-Sun : Emergencies Only</p>
                        </div>
                        <div className="store-hours foot-all">
                            <h3>Store Hours</h3>
                            <p>Mon-Fri : 8:00am -2:00pm</p>
                            <p>Sat-Sun : Closed</p>
                        </div>
                    </div>
                </div>
                <div className="footer-line"></div>
            </footer>
        </>
    )
}

export default Footer