import React, { useEffect, useState } from 'react';

const Homepage = () => {

  const [activePage, setActivePage] = useState('Homepage');

  useEffect(() => {
    let listGroupItem = Array.from(document.getElementsByClassName("list-group-item"));
    listGroupItem.forEach(i => {
      i.classList.remove("nav-active");
    });
    let activeID = document.getElementById(activePage);
    if (activeID) {
      activeID.classList.add("nav-active");
    }
  })

  useEffect(() => {
    const slider = document.querySelector('.slider .list');
    const items = document.querySelectorAll('.slider .list .item');
    const lengthItems = items.length - 1;
    let active = 0;

    const refreshInterval = setInterval(() => {
      active = active + 1 <= lengthItems ? active + 1 : 0;
      reloadSlider();
    }, 5000);

    function reloadSlider() {
      slider.style.left = -items[active].offsetLeft + 'px';
    }

    window.onresize = function (event) {
      reloadSlider();
    };

    const feedbackBoxes = document.querySelectorAll('.feedback-box');
    let currentIndex = 0;

    function showNextFeedback() {
      currentIndex = (currentIndex + 2) % feedbackBoxes.length;
      showFeedback(currentIndex);
    }

    function showPrevFeedback() {
      currentIndex = (currentIndex - 2 + feedbackBoxes.length) % feedbackBoxes.length;
      showFeedback(currentIndex);
    }

    function showFeedback(index) {
      const totalBoxes = feedbackBoxes.length;

      for (let i = 0; i < totalBoxes; i++) {
        if (i === index || i === (index + 1) % totalBoxes) {
          feedbackBoxes[i].style.display = 'flex';
        } else {
          feedbackBoxes[i].style.display = 'none';
        }
      }
    }

    const angleRightButton = document.querySelector('.fa-angle-right');
    const angleLeftButton = document.querySelector('.fa-angle-left');

    angleRightButton.addEventListener('click', showNextFeedback);
    angleLeftButton.addEventListener('click', showPrevFeedback);

    showFeedback(currentIndex);

    const allButton = document.querySelector('.filter-buttons button[data-filter=".electrical"]');
    allButton.classList.add('active');

    const filterButtons = document.querySelectorAll('.filter-buttons button');
    const imageContainers = document.querySelectorAll('.work-image .w-image');
    const viewMoreBtn = document.getElementById('image-btn');

    filterButtons.forEach(button => {
      button.addEventListener('click', function () {
        filterButtons.forEach(btn => btn.classList.remove('active'));
        button.classList.add('active');
        imageContainers.forEach(container => container.classList.add('hidden'));

        const filterValue = button.getAttribute('data-filter');

        if (filterValue === '*') {
          toggleAdditionalImages();
        } else {
          const selectedImages = document.querySelector(`.work-image ${filterValue}`);
          selectedImages.classList.remove('hidden');
        }
      });
    });

    function toggleAdditionalImages() {
    }

    return () => {
      clearInterval(refreshInterval);
    };
  }, []);

  return (
    <>
      <div className="slider">
        <div className="list">
          <div className="item">
            <img src="/assets/images/img2_.png" alt="" />
            <div className="overlay text4">
              <h2>Electrical Upgrades,<br />Repairs, and Rewring</h2>
              <a href="#quote"><button className="button">Get A Free Quote</button></a>
            </div>
          </div>
          <div className="item">
            <img src="/assets/images/img1.png" alt="" />
            <div className="overlay text2">
              <h2>
                Helping You Stay <br />Warm During the <br />Cold, Winter Months
              </h2>
              <a href="#quote"><button className="button button-red">Get A Free Quote</button></a>
            </div>
          </div>
          <div className="item">
            <img src="/assets/images/img4.png" alt="" />
            <div className="overlay text1">
              <h2>Plumbing System <br />Installation & Repairs</h2>
              <a href="#quote"><button className="button">Get A Free Quote</button></a>
            </div>
          </div>
          <div className="item">
            <img src="/assets/images/img3_.jpg" alt="" />
            <div className="overlay text3">
              <h2>We Dig Ditches & <br />Trenches</h2>
              <a href="#quote"><button className="button">Get A Free Quote</button></a>
            </div>
          </div>
        </div>
      </div>

      <div className="services">
        <div className="intro">
          <h2>
            Your Trusted Electrical, HVAC, <br /> and Plumbing Contractor for Your <br /> Home
            or Business
          </h2>
          <p>
            LJL Electrical, Heating & Cooling Inc. offers expert solutions for
            your home and commercial business. We are fully certified and insured
            technicians that handle any job thrown our way. As a full-service
            electrical, HVAC, and plumbing contractor, we make our customers feel
            comfortable and provide a pleasant experience. Call us today at
            716-937-4628 to schedule a service in Alden, Erie, Genesee, and
            Wyoming counties and surrounding areas.
          </p>
        </div>
        <div className="services-list">
          <div className="service-container">
            <img src="../assets/images/electrical.png" alt="" />
            <h2>Electrical</h2>
            <p>
              Upgrade electrical panels, install whole- house generators, repair
              outlets, circuits and receptacles
            </p>
            <a href="/electrical">
              <button>Learn More</button>
            </a>
          </div>
          <div className="service-container">
            <img src="../assets/images/heating.png" alt="" />
            <h2>Heating</h2>
            <p>
              Install any make and model Furnaces, boilers, and other heating
              systems, as well as maintenance heating solutions
            </p>
            <a href="/heating">
              <button>Learn More</button>
            </a>
          </div>
          <div className="service-container">
            <img src="../assets/images/cooling.png" alt="" />
            <h2>Cooling</h2>
            <p>
              Repair and installation for air conditioners, mini-split systems,
              and thermostats.
            </p>
            <a href="/cooling">
              <button>Learn More</button>
            </a>
          </div>
          <div className="service-container">
            <img src="../assets/images/pumbling.png" alt="" />
            <h2>Plumbing</h2>
            <p>
              Service water heaters, gas lines, faucets, drains, pipes, and well
              pumps.
            </p>
            <a href="/plumbing">
              <button id="button-plumbing">Learn More</button>
            </a>
          </div>
          <div className="service-container">
            <img src="/assets/images/mini excavation.png" alt="" />
            <h2>Mini Excavation</h2>
            <p>
              Dig trenches for gas lines, water lines, phone lines, and cable
              lines
            </p>
            <a href="/mini">
              <button>Learn More</button>
            </a>
          </div>
        </div>
      </div>

      {/* <!-- service part end here  --> */}

      {/* <!-- our work starts here  --> */}


      <div className="our_work">
        <h2>Some of Our Work</h2>
        <div className="filter-buttons">
          {/* <!-- <button data-filter=".all">All</button> --> */}
          <button data-filter=".electrical">Electrical</button>
          <button data-filter=".heating">Heating</button>
          <button data-filter=".cooling">Cooling</button>
          <button data-filter=".plumbing">Plumbing</button>
          <button data-filter=".mini-excavation">Mini Excavation</button>
        </div>
        <div className="work-image" >
          <div className="w-image electrical">
            <img src="../assets/images/e1.png" alt="" />
            <img src="../assets/images/e2.png" alt="" />
            <img src="../assets/images/e3.png" alt="" />
            <img src="../assets/images/e4.png" alt="" />
            <img src="../assets/images/e5.png" alt="" />
            <img src="../assets/images/e6.png" alt="" />
            <img src="../assets/images/e7.png" alt="" />
            <img src="../assets/images/e8.png" alt="" />
            <img src="../assets/images/e9.png" alt="" />
            <img id="elec-extra-hide" src="../assets/images/extra.png" alt="" />


          </div>
          <div className="w-image heating hidden">
            <img src="../assets/images/h1.png" alt="" />
            <img src="../assets/images/h2.png" alt="" />
            <img src="../assets/images/h3.png" alt="" />
            <img src="../assets/images/h4.png" alt="" />
            <img src="../assets/images/h5.png" alt="" />
            <img src="../assets/images/h6.png" alt="" />
            <img src="../assets/images/h7.png" alt="" />
            <img id="heat-extra-hide-1" src="../assets/images/extra.png" alt="" />
            <img id="heat-extra-hide-2" src="../assets/images/extra.png" alt="" />
            <img id="heat-extra-hide-3" src="../assets/images/extra.png" alt="" />

          </div>
          <div className="w-image cooling hidden">
            <img src="../assets/images/c.png" alt="" />
          </div>


          <div className="w-image plumbing hidden">
            <img src="../assets/images/p1.png" alt="" />
            <img src="../assets/images/p2.png" alt="" />
            <img src="../assets/images/p3.png" alt="" />
            <img id="plu-extra-hide-1" src="../assets/images/extra.png" alt="" />
            <img id="plu-extra-hide-2" src="../assets/images/extra.png" alt="" />
          </div>
          <div className="w-image plumbing hidden">
            <img src="../assets/images/p1.png" alt="" />
            <img src="../assets/images/p2.png" alt="" />
            <img src="../assets/images/p3.png" alt="" />
          </div>
          <div className="w-image mini-excavation hidden">
            <img src="../assets/images/m1.png" alt="" />
            <img src="../assets/images/2.png" alt="" />
            <img id="mini-extra-hide-1" src="../assets/images/extra.png" alt="" />
            <img id="mini-extra-hide-2" src="../assets/images/extra.png" alt="" />
            <img id="mini-extra-hide-3" src="../assets/images/extra.png" alt="" />
          </div>
        </div>
        {/* <!-- <button id="image-btn">View More</button> --> */}
      </div>

      <div className="feedback">
        <h2>Some Feedback From Our Customers</h2>
        <div className="feedback-container">
          <div className="feedback-box">
            <div className="customer">
              <div className="customer-image">
                {/* <img src="../assets/images/1.jpg" alt="" /> */}
              </div>
              <div className="customer-name">
                <h3>Lauren Kinney</h3>
              </div>
              <div className="star">
                <i class="fa-sharp fa-solid fa-star"></i>
                <i class="fa-sharp fa-solid fa-star"></i>
                <i class="fa-sharp fa-solid fa-star"></i>
                <i class="fa-sharp fa-solid fa-star"></i>
                <i class="fa-sharp fa-solid fa-star"></i>
              </div>
            </div>
            <div className="feedback_text">
              <p>
                My husband and I have been going to LJL for years for electrical and heating repair work. It is,great having a local handy man we can rely on.
                Larry and Rory are prompt and professional. They complete their work quickly and thoroughly, and have gotten us out of more than one emergency. Most recently, our heater stopped working right before a storm was coming in. Rory was there within 10 minutes and the heat was back on 20 minutes later. I couldn't have asked for better!
              </p>
            </div>
          </div>
          <div className="feedback-box">
            <div className="customer">
              <div className="customer-image">
                {/* <img src="../assets/images/3.jpg" alt="" /> */}
              </div>
              <div className="customer-name">
                <h3>Kerry Henderson</h3>
              </div>
              <div className="star">
                <i class="fa-sharp fa-solid fa-star"></i>
                <i class="fa-sharp fa-solid fa-star"></i>
                <i class="fa-sharp fa-solid fa-star"></i>
                <i class="fa-sharp fa-solid fa-star"></i>
                <i class="fa-sharp fa-solid fa-star"></i>
              </div>
            </div>
            <div className="feedback_text">
              <p>
                Lil heating they are very nice and do a great job I'm very happy them they do excellent work.
              </p>
            </div>
          </div>

          <div className="feedback-box">
            <div className="customer">
              <div className="customer-image">
                {/* <img src="../assets/images/3.jpg" alt="" /> */}
              </div>
              <div className="customer-name">
                <h3>Chris C</h3>
              </div>
              <div className="star">
                <i class="fa-sharp fa-solid fa-star"></i>
                <i class="fa-sharp fa-solid fa-star"></i>
                <i class="fa-sharp fa-solid fa-star"></i>
                <i class="fa-sharp fa-solid fa-star"></i>
                <i class="fa-sharp fa-solid fa-star"></i>
              </div>
            </div>
            <div className="feedback_text">
              <p>
                My Rinnai combination boiler-hot water system shutdown due to a failed sensor. Larry and Rory responded quickly to my distress call. They diagnosed the problem, arranged to have the replacement sensor overnighted from manufacturer through their local supplier, and returned to complete the repair as soon as the sensor arrived. I appreciate their care and diligence. Thanks again guys!
              </p>
            </div>
          </div>

          <div className="feedback-box">
            <div className="customer">
              <div className="customer-image">
                {/* <img src="../assets/images/3.jpg" alt="" /> */}
              </div>
              <div className="customer-name">
                <h3>Susan Mooney</h3>
              </div>
              <div className="star">
                <i class="fa-sharp fa-solid fa-star"></i>
                <i class="fa-sharp fa-solid fa-star"></i>
                <i class="fa-sharp fa-solid fa-star"></i>
                <i class="fa-sharp fa-solid fa-star"></i>
                <i class="fa-sharp fa-solid fa-star"></i>
              </div>
            </div>
            <div className="feedback_text">
              <p>
                I had an emergency situation they came right over and made the situation safe. They said they would be back in two days to complete the job. They were right on time and did an amazing job. They were friendly and professional and were genuinely concerned for my safety. I was charged a fair price and would definitely call them again if needed. I highly recommend this company.
              </p>
            </div>
          </div>
        </div>
        <div class="angle-button">
          <i class="fa-solid fa-angle-left" id='left-angle-button'></i>
          <i class="fa-solid fa-angle-right"></i>
        </div>
      </div>

      {/* <!-- feedback ends here  --> */}

      <div class="text-cont">
        <p>
          <b>For Emergencies at any hour</b> - your issue is referred to our experienced personnel who will answer
          when you call to gather all the pertinent information to ensure that the correct resources are dispatched
          to your location. The initial resttponse is spearheaded by one of our most experienced electrical and/or
          HVAC technicians in a stocked service truck. If it is determined that additional support is needed, we can
          call upon our own resources or access numerous supply houses throughout Erie, Genesee, and
          Wyoming counties who will open their facility for us at any hour of the day or night. <b>LJL Electrical,
            Heating & Cooling, Inc.</b> understands the importance of keeping our clients’ systems and production up
          and running. System failure can mean large revenue losses and loss of customer confidence. We
          understand the urgency of the situation and at the same time the critical need for the safety of
          everyone involved in the operation.
        </p>
      </div>

      {/* <!-- store part starts here  --> */}
      <div className="store">
        <div className="store-content">
          <div className="store_des">
            <h2>Come and  Visit our Store</h2>
            <p>We have The Largest Assortment of Electrical Breakers around town between Batavia and Cheektowaga. We have anything from faceplates to breakers, conduit fittings, piping to well pumps. IF WE DON’T HAVE IT, WE CAN ORDER IT FOR YOU WITHIN A DAY OR TWO.
              <br /> Our temporary store hours are Monday – Friday 8:00am to 2:00pm. Visit us today at <span><a href="#map">13419 Broadway, Alden, NY 14004.</a></span></p>
          </div>
          <div className="store_img">
            <img src="../assets/images/store.png" alt="" />
          </div>
        </div>
      </div>

      {/* <!-- store part ends here  --> */}

      {/* <div className="coupons">
        <h2>Save with HVAC Coupons</h2>
        <div className="coupons-container">

          <div className="coupons-box">
            <div className="coupons-img">
              <img src="../assets/images/coupons.png" alt="" />
            </div>
            <button><i class="fa-solid fa-print"></i>  Print Coupon</button>
          </div>
          <div className="coupons-box">
            <div className="coupons-img">
              <img src="../assets/images/coupons.png" alt="" />
            </div>
            <button><i class="fa-solid fa-print"></i>  Print Coupon</button>
          </div>
          <div className="coupons-box">
            <div className="coupons-img">
              <img src="../assets/images/coupons.png" alt="" />
            </div>
            <button><i class="fa-solid fa-print"></i>  Print Coupon</button>
          </div>
          <div className="coupons-box">
            <div className="coupons-img">
              <img src="../assets/images/coupons.png" alt="" />
            </div>
            <button><i class="fa-solid fa-print"></i>  Print Coupon</button>
          </div>
          <div className="coupons-box">
            <div className="coupons-img">
              <img src="../assets/images/coupons.png" alt="" />
            </div>
            <button><i class="fa-solid fa-print"></i>  Print Coupon</button>
          </div>
          <div className="coupons-box">
            <div className="coupons-img">
              <img src="../assets/images/coupons.png" alt="" />
            </div>
            <button ><i class="fa-solid fa-print"></i>  Print Coupon</button>
            <div id="quote"></div>
          </div>
        </div>
      </div> */}

      {/* <!-- coupons ends here  --> */}

      <div class="text-cont">
        <p>
          <b>LJL Electrical, Heating & Cooling, Inc.</b> has a dedicated customer care line open to answer any questions
          or concerns you may have. We provide a free, no obligation consultation with each and every one of our
          prospective customers to thoroughly assess their unique needs. Moreover, all our technicians are fully
          certified and insured. We also educate ourselves on a continual basis by regularly attending seminars,
          conferences, and workshops on new developments in the fields of mechanical and industrial
          engineering to stay ahead of the game and provide the most innovative and expert service possible. In
          addition, all the electrical parts and labor that we provide come with a multi-year warranty for complete
          peace of mind. <br /><br />
          As a full-service company, we handle repairs, preventative maintenance, installations, troubleshooting,
          consultations, planning, and more. Please contact us today and discover the <b>LJL Electrical, Heating & Cooling, Inc.</b> difference for yourself.
        </p>
        <div id="quote"></div>
      </div>


      {/* <!-- map and form goes here  --> */}
      <div className="map" id="map">
        <div className="map-place">
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2922.7212752337864!2d-78.49040042378529!3d42.89982707114786!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d3a2a198b14431%3A0x3e8454430762cfee!2sLJL%20Electrical%2C%20Heating%20%26%20Cooling%20Inc!5e0!3m2!1sen!2snp!4v1702232525399!5m2!1sen!2snp" style={{ height: 450, border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
        <div className="form">
          <h2>Get Your FREE Quote Today!</h2>
          <form action="#" method="post" >
            <div className="form-content">
              <div className="form-name">
                <label for="name">Name</label>
                <input type="text" name="name" id="name" placeholder="Enter Your Name" />
              </div>

              <div className="form-number">
                <label for="phone">Phone Number</label>
                <input type="number" name="phone" id="phone" placeholder="Enter Your Phone Number" />
              </div>

              <div className="form-email">
                <label for="email">E-mail</label>
                <input type="email" name="email" id="email" placeholder="Enter Your Email" />
              </div>

              <div className="form-message">
                <label for="message">How can we help?</label>
                <input type="text" name="message" id="message" placeholder="Your Message" />
              </div>

              <button type="submit"><i class="fa-solid fa-envelope" style={{ marginRight: 15 }}></i> Submit</button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default Homepage