import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const Cooling = () => {

  const [activePage, setActivePage] = useState('service');

  useEffect(() => {
    let listGroupItem = Array.from(document.getElementsByClassName("list-group-item"));
    listGroupItem.forEach(i => {
      i.classList.remove("nav-active");
    });
    let activeID = document.getElementById(activePage);
    if (activeID) {
      activeID.classList.add("nav-active");
    }
  })

  return (
    <>
      <div className="ser-plumbing-img">
        <img src="../assets/images/img1.png" alt="" />
        <div className="ser-overlay"></div>
        <div className="ser-text-container">
          <p>Our Services/Cooling</p>
          <h2>Keep Your Family Cool During The Summer Heat</h2>
        </div>
      </div>

      <div className="ser-body">
        <div className="ser-container">
          <div className="ser-services">
            <Link to="/electrical">
              <div className="ser-electric serv">
                <p>Electrical</p>
                <img src="/assets/icon/elec_icon.png" alt="" />
              </div>
            </Link>

            <Link to="/heating">
              <div className="ser-heating serv">
                <p>Heating</p>
                <img src="/assets/icon/heating_icon.png" alt="" />
              </div>
            </Link>

            <Link to="/cooling">
              <div className="ser-cooling serv ser-active">
                <p>Cooling</p>
                <img src="/assets/icon/cooling_icon_w.png" alt="" />
              </div>
            </Link>

            <Link to="/plumbing">
              <div className="ser-plumbing serv">
                <p>Plumbing</p>
                <img src="/assets/icon/plumbing_icon.png" alt="" />
              </div>
            </Link>

            <Link to="/mini">
              <div className="ser-mini serv">
                <p>Mini Excavation</p>
                <img src="/assets/icon/exa_icon.png" alt="" />
              </div>
            </Link>
          </div>

          <div className="ser-contact">
            <h2>Contact Us</h2>
            <div id="quote"></div>
            <p>
              <i style={{marginRight: 15}} class="fa-solid fa-phone"></i> <a href="tel:7169374628">716-937-4628</a>
            </p>
            <p>
              <i style={{marginRight: 15}} class="fa-solid fa-envelope"></i>
              <a href="mailTo:ljl@ljlehvac.com"> ljl@ljlehvac.com</a>
            </p>
          </div>

          <div className="ser-quote">
            <h2>Get A Quote</h2>
            <form action="#" method="post">
              <div className="ser-form-content">
                <div className="ser-form-name ser-form">
                  <label for="name">Name</label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Enter Your Name"
                  />
                </div>

                <div className="ser-form-number ser-form">
                  <label for="phone">Phone Number</label>
                  <input
                    type="number"
                    name="phone"
                    id="phone"
                    placeholder="Enter Your Phone Number"
                  />
                </div>

                <div className="ser-form-email ser-form">
                  <label for="email">E-mail</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter Your Email"
                  />
                </div>

                <div className="ser-form-message ser-form">
                  <label for="message">How can we help?</label>
                  <textarea
                    name="message"
                    id="message"
                    placeholder="Your Message"
                    rows="5"
                    cols="30"
                  ></textarea>
                </div>
                <div className="ser-btn">
                  <button type="submit">
                    <i style={{marginRight: 15}} class="fa-solid fa-envelope"></i> Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="ser-details">
          <h2>Cooling Service</h2>
          <p>
            We are your preferred and local HVAC company in Alden, Erie, Genesee,
            and Wyoming counties and surrounding areas to ensure there is no
            downtime with your cooling system. A dependable and consistently
            working AC unit fills give your place cool air to keep you cool during
            the summer. When your Air Conditioner fails, it can become an
            inconvenience and uncomfortable situation. Call <a href="tel:7169374628">716-937-4628</a> to
            schedule an AC service, installation, or repair.
          </p>
          <br />
          <div className="ser-list">
            <div className="ser-list1">
              <ul>
                <li>Thermostats</li>
                <li>Mini-Split Systems</li>
              </ul>
            </div>

            <div className="ser-list2">
              <ul>
                <li>Air Conditioners</li>
                <li>Air Handlers</li>
              </ul>
            </div>

            <div className="ser-list3">
              <ul>
                <li>Duct Works</li>
              </ul>
            </div>
          </div>

          <div className="ser-img1-cont">
            <div className="ser-img1">
              <img src="../assets/images/heating_1.png" alt="" />
            </div>
            <div className="ser-img1-details ser-img1-np">
              <h2>AC Unit Installation & Repairs</h2>
              <p>
                When it is hot outside and your Air Conditioning is not working,
                you want a quick fix. Stay comfortable in your own home with air
                conditioning services from our certified technicians. We install a
                new AC system or make repairs to your current AC unit. Whether
                your air conditioner is making strange noises, leaking water, or
                not shooting cool air, we’re available for all your AC
                emergencies. Our team has experience fixing all kinds of problems.
              </p>
            </div>
          </div>

          <div className="ser-img2-cont">
            <div className="ser-img2-details ser-img2-np">
              <h2>Air Handlers</h2>
              <p>
                One of the most important components of the air handler. This part
                contains the blower, filter racks, sound attenuators, dampers, and
                cooling elements. This part circulates the air in the rooms and if
                they are not functioning properly, your electric bill can
                increase. Call us at <a href="tel:7169374628">716-937-4628</a> immediately if you
                think your air handler is broken. We want to help you save money
                and improve the air quality. <br />Commercial Air Conditioning
                Installation and Repairs
              </p>
            </div>
            <div className="ser-img2">
              <img src="../assets/images/heating_2.png" alt="" />
            </div>
          </div>

          <div className="ser-mid-cont">
            <h2>Commercial Air Conditioning Installation And Repairs</h2>
            <p>
              We have worked with many types of businesses including retail shops,
              restaurants, and offices, to help them with their commercial air
              conditioner system. From AC unit repairs and installation to cooling
              system replacements and maintenance, we are the HVAC experts
              dedicated to your satisfaction. Our goal is to ensure your
              commercial cooling system is running efficiently. We’re available
              for emergencies.
            </p>
          </div>

          <div className="ser-img1-cont">
            <div className="ser-img1">
              <img src="../assets/images/cooling3.png" alt="" />
            </div>
            <div className="ser-img1-details ser-img1-np">
              <h2>Air Conditioner Preventative Maintenance</h2>
              <p>
                When you need cooling, your company or home depends on the
                performance of your air conditioning systems. But keeping your AC
                system in its original peak performance condition year after year
                demands maintenance and small repairs. Call 
                <a href="tel:7169374628"> 716-937-4628</a> to learn about our maintenance plans.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Cooling