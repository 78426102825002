import React, { useEffect, useState } from 'react'

const Contact = () => {

  const [activePage, setActivePage] = useState('contact');

  useEffect(() => {
    let listGroupItem = Array.from(document.getElementsByClassName("list-group-item"));
    listGroupItem.forEach(i => {
      i.classList.remove("nav-active");
    });
    let activeID = document.getElementById(activePage);
    if (activeID) {
      activeID.classList.add("nav-active");
    }
  })

  return (
    <>
        {/* <!-- header part starts here  --> */}
    <div className="ser-plumbing-img">
      <img src="/assets/images/img4.png" alt="" />
      <div className="ser-overlay"></div>
      <div className="ser-text-container">
        <p>Home/Contact Us</p>
        <h2>Got questions? Reach out to us!</h2>
      </div>
    </div>
    {/* <!-- body part starts here  --> */}

    <div className="contact-details">
    <a href="tel:7169374628" class="contact-links">
        <div class="contact-container">
            <p><i style={{marginRight: 15}} class="fa-solid fa-phone"></i> Phone</p>
            <p class="text-color">(716)937-4628</p>
        </div>
      </a>
      <a href="mailto:ljl@ljlehvac.com" class="contact-links">
        <div class="contact-container">
            <p><i style={{marginRight: 15}} class="fa-solid fa-envelope"></i> Email</p>
            <p class="text-color">ljl@ljlehvac.com</p>
        </div>
      </a>
      {/* <a href="fax:7169025045" class="contact-links">
        <div class="contact-container">
            <p><i style={{marginRight: 15}} class="fa-solid fa-fax"></i> Fax</p>
            <p class="text-color">(716)902-5045</p>
        </div>
      </a> */}
    </div>

    <div className="contact-map-form">
        <div className="contact-map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2922.7212752337864!2d-78.49040042378529!3d42.89982707114786!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d3a2a198b14431%3A0x3e8454430762cfee!2sLJL%20Electrical%2C%20Heating%20%26%20Cooling%20Inc!5e0!3m2!1sen!2snp!4v1702232525399!5m2!1sen!2snp" style={{width:"600", height:"450", style:"border:0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>  
        </div>
        <div className="contact-form">
            <h2>Get Your FREE Quote Today!</h2>
            <form action="#" method="post" >
            <div className="contact-form-content">
                  <div className="contact-form-name">
                    <label for="name">Name</label>
                    <input type="text" name="name" id="name" placeholder="Enter Your Name" />
                  </div>
    
                  <div className="contact-form-number">
                    <label for="phone">Phone Number</label>
                    <input type="number" name="phone" id="phone" placeholder="Enter Your Phone Number" />
                  </div>
    
                  <div className="contact-form-email">
                    <label for="email">E-mail</label>
                    <input type="email" name="email" id="email" placeholder="Enter Your Email" />
                  </div>
    
                  <div className="contact-form-message">
                    <label for="message">How can we help?</label>
                    <input type="text" name="message" id="message" placeholder="Your Message" />
                  </div>
                  <button type="submit">Submit</button>
                </div>
              </form>
        </div>
    </div>
    </>
  )
}

export default Contact