import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const Service = () => {

  const [activePage, setActivePage] = useState('service');

  useEffect(() => {
    let listGroupItem = Array.from(document.getElementsByClassName("list-group-item"));
    listGroupItem.forEach(i => {
      i.classList.remove("nav-active");
    });
    let activeID = document.getElementById(activePage);
    if (activeID) {
      activeID.classList.add("nav-active");
    }
  })
  return (
    <>
      <div className="ser-plumbing-img">
        <img src="../assets/images/img4.png" alt="" />
        <div className="ser-overlay"></div>
        <div className="ser-text-container">
          <p>Our Services/Plumbing</p>
          <h2>Plumbing System Installation & Repairs</h2>
        </div>
      </div>
      {/* <!-- body part starts here  --> */}
      <div className="ser-body">
        <div className="ser-container">

          <div className="ser-services">
            <Link to="/electrical">
              <div className="ser-electric serv">
                <p>Electrical</p>
                <img src="/assets/icon/elec_icon.png" alt="" />
              </div>
            </Link>

            <Link to="/heating">
              <div className="ser-heating serv">
                <p>Heating</p>
                <img src="/assets/icon/heating_icon.png" alt="" />
              </div>
            </Link>

            <Link to="/cooling">
              <div className="ser-cooling serv">
                <p>Cooling</p>
                <img src="/assets/icon/cooling_icon.png" alt="" />
              </div>
            </Link>

            <Link to="/plumbing">
              <div className="ser-plumbing serv">
                <p>Plumbing</p>
                <img src="/assets/icon/plumbing_icon.png" alt="" />
              </div>
            </Link>

            <Link to="/mini">
              <div className="ser-mini serv">
                <p>Mini Excavation</p>
                <img src="/assets/icon/exa_icon.png" alt="" />
              </div>
            </Link>
          </div>
          <div className="ser-contact">
            <h2>Contact Us</h2>
            <p><i style={{marginRight: 15}} class="fa-solid fa-phone"></i> <a href="tel:7169374628">716-937-4628</a></p>
            <p><i style={{marginRight: 15}} class="fa-solid fa-envelope"></i> <a href="mailTo:ljl@ljlehvac.com">ljl@ljlehvac.com</a></p>
          </div>

          <div className="ser-quote">
            <h2>Get A Quote</h2>
            <form action="#" method="post">
              <div className="ser-form-content">
                <div className="ser-form-name ser-form">
                  <label for="name">Name</label>
                  <input type="text" name="name" id="name" placeholder="Enter Your Name" />
                </div>

                <div className="ser-form-number ser-form">
                  <label for="phone">Phone Number</label>
                  <input type="number" name="phone" id="phone" placeholder="Enter Your Phone Number" />
                </div>

                <div className="ser-form-email ser-form">
                  <label for="email">E-mail</label>
                  <input type="email" name="email" id="email" placeholder="Enter Your Email" />
                </div>

                <div className="ser-form-message ser-form">
                  <label for="message">How can we help?</label>
                  <textarea name="message" id="message" placeholder="Your Message" rows="5" cols="30"></textarea>
                </div>
                <div className="ser-btn">
                  <button type="submit"><i style={{marginRight: 15}} class="fa-solid fa-envelope"></i> Submit</button>
                </div>
              </div>
            </form>
          </div>

        </div>

        <div className="ser-details">
          <h2>Plumbing Services</h2>
          <p>
            Over many years of quality and professional services, LJL Electrical,
            Heating & Cooling Inc., has acquired the ability to quickly and
            accurately diagnose the work that needs to be done in your plumbing
            system. This makes it possible for us to provide you with a realistic
            and fair estimate before any service has begun. We service Alden,
            Erie, Genesee, and Wyoming counties and surrounding areas with more
            than just plumbing issues. Our team also handles plumbing
            installation, hot water heaters, well pumps, and tankless water
            heaters at homes and businesses.
          </p>
          <br />
          <h2>What Do We Offer ?</h2>
          <p>
            Over many years of quality and professional services, LJL Electrical,
            Heating & Cooling Inc., has acquired the ability to quickly and
            accurately diagnose the work that needs to be done in your plumbing
            system. This makes it possible for us to provide you with a realistic
            and fair estimate before any service has begun. We service Alden,
            Erie, Genesee, and Wyoming counties and surrounding areas with more
            than just plumbing issues. Our team also handles plumbing
            installation, hot water heaters, well pumps, and tankless water
            heaters at homes and businesses.
          </p>

          <div className="ser-img1-cont">
            <div className="ser-img1">
              <img src="../assets/images/ser_image1.png" alt="" />
            </div>
            <div className="ser-img1-details">
              <ul>
                <li>Sewer & Septic Service</li>
                <p>
                  We make repairs and replace sewer lines, distribution tanks,
                  septic lines, and all household drains.
                </p>
              </ul>
              <ul>
                <li>Baseboard Heaters</li>
                <p>
                  We repair all brands, make new installations, and upgrade and
                  resize your system.
                </p>
              </ul>
              <ul>
                <li>Water and Gas Lines</li>
                <p>
                  We locate and repair leaks as well as make replacement of faulty
                  lines, install new ones and re-route.
                </p>
              </ul>
            </div>
          </div>

          <div className="ser-img2-cont">
            <div className="ser-img2-details">
              <ul>
                <li>Hot Water Heaters</li>
                <p>
                  We install hot water tanks like gravity vents, metal vents, power vents, and tankless water heaters.
                </p>
              </ul>
              <ul>
                <li>Plumbing Inspections</li>
                <p>
                  Residential and commercial gas inspections, pipe repair, as well as interior and exterior drains, and leach lines.
                </p>
              </ul>
              <ul>
                <li>Faucets</li>
                <p>
                  We install all types of faucets and make recommendations for upgrades and provide new water lines.
                </p>
              </ul>
            </div>
            <div className="ser-img2">
              <img src="../assets/images/ser_image2.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Service