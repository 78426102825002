import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Homepage from './pages/Homepage';
import Cooling from './pages/Cooling';
import Electrical from './pages/Electrical';
import Heating from './pages/Heating';
import Mini_excavation from './pages/Mini_excavation';
import Plumbing from './pages/Plumbing';
import Service from './pages/Service';
import Coupons from './pages/Coupons';
import Contact from './pages/Contact';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/cooling" element={<Cooling />} />
        <Route path="/electrical" element={<Electrical />} />
        <Route path="/heating" element={<Heating />} />
        <Route path="/mini" element={<Mini_excavation />} />
        <Route path="/plumbing" element={<Plumbing />} />
        <Route path="/service" element={<Service />} />
        <Route path="/coupons" element={<Coupons />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
