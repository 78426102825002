import React, { useEffect, useState } from 'react'

const Coupons = () => {

  const [activePage, setActivePage] = useState('coupons');

  useEffect(() => {
    let listGroupItem = Array.from(document.getElementsByClassName("list-group-item"));
    listGroupItem.forEach(i => {
      i.classList.remove("nav-active");
    });
    let activeID = document.getElementById(activePage);
    if (activeID) {
      activeID.classList.add("nav-active");
    }
  })
  
  useEffect(() => {
    const printButtons = document.querySelectorAll('.print-coupons');
  
    printButtons.forEach(function (button) {
      button.addEventListener('click', function () {
        var couponsContainer = this.closest('.coupons-container');
        if (couponsContainer) {
          var printWindow = window.open('', '_blank');
          
          if (printWindow) {
            printWindow.document.write('<html><head><title>Coupon</title>');
            printWindow.document.write('<link rel="stylesheet" type="text/css" href="../style.css">');
            printWindow.document.write('</head><body>');
            var clonedContainer = couponsContainer.cloneNode(true);
            modifyContent(clonedContainer);
            printWindow.document.body.appendChild(clonedContainer);
  
            printWindow.document.write('</body></html>');
            printWindow.document.close();
            printWindow.print();
          } else {
            console.error('Popup window was blocked or failed to open.');
          }
        }
      });
    });
  
    function modifyContent(container) {
      var printButton = container.querySelector('.print-coupons');
      if (printButton) {
        printButton.remove();
      }
    }
  }, []); // Empty dependency array to ensure the effect runs only once on mount
  
  return (
    <>
      {/* <!-- header part starts here  --> */}
      <div class="ser-plumbing-img">
        <img src="/assets/images/img1.png" alt="" />
        <div class="ser-overlay"></div>
        <div class="ser-text-container">
          <p>Coupons</p>
          <h2>Save Money with the Coupons Below</h2>
        </div>
      </div>
      {/* <!-- body part starts here  --> */}
      <div class="coupons-body">
        <div class="ser-container">
          <div class="note">
            <h3>Notes</h3>
            <ul>
              <li>
                Coupons must be presented to an LJL representative either during
                the time of repair, consultation, quote, or service replacement
                agreement.
              </li>
              <li>
                Each coupon is valid for one service, cannot be combined with
                other offers, and has a limit of one per person or situation.
              </li>
            </ul>
          </div>

          <div class="ser-contact">
            <h2>Contact Us</h2>
            <div id="quote"></div>
            <p>
              <i style={{marginRight: 15}} class="fa-solid fa-phone"></i
              ><a href="tel:7169374628" style={{ textDecoration: 'none' }}
              > (716)937-4628</a
              >
            </p>
            <p>
              <i style={{marginRight: 15}} class="fa-solid fa-envelope"></i
              ><a href="mailto:ljl@ljlehvac.com" style={{ textDecoration: 'none' }}
              > ljl@ljlehvac.com</a
              >
            </p>
            {/* <p>
              <i style={{marginRight: 15}} class="fa-solid fa-fax"></i
              ><a href="fax:7169025045" style={{ textDecoration: 'none' }}>
                (716)902-5045</a
              >
            </p> */}
          </div>

          <div class="ser-quote">
            <h2>Get A Quote</h2>
            <form action="#" method="post">
              <div class="ser-form-content">
                <div class="ser-form-name ser-form">
                  <label for="name">Name</label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Enter Your Name"
                  />
                </div>

                <div class="ser-form-number ser-form">
                  <label for="phone">Phone Number</label>
                  <input
                    type="number"
                    name="phone"
                    id="phone"
                    placeholder="Enter Your Phone Number"
                  />
                </div>

                <div class="ser-form-email ser-form">
                  <label for="email">E-mail</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter Your Email"
                  />
                </div>

                <div class="ser-form-message ser-form">
                  <label for="message">How can we help?</label>
                  <textarea
                    name="message"
                    id="message"
                    placeholder="Your Message"
                    rows="5"
                    cols="30"
                  ></textarea>
                </div>
                <div class="ser-btn">
                  <button type="submit">
                    <i style={{marginRight: 15}} class="fa-solid fa-envelope"></i>Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="coupons">
          <div class="coupons-container">
            <div class="coupons-left">
              <div class="coupons-call">
                <h3>Emergency Call 24/7 (716) 937-4628 or (585) 815-7658</h3>
              </div>
              <div class="coupons-left-body">
                <h3>Electrical Repairs</h3>
                <p>
                  If your electrical system goes haywire and you have a problem
                  with your existing service, or a problem with outlets, switches
                  or lighting. Or you just want to have some rewiring done.
                  <br /><b
                  >(i.e : breakers not working, bad breakers, shorted wires, bad
                    light fixtures etc.)</b
                  >
                </p>
                <button>Redeemable During Any Time</button>
              </div>
            </div>

            <div class="coupons-right">
              <div class="coupons-discount">
                <img src="/assets/images/logo.png" alt="logo" />
                <h3>$50</h3>
                <button>Off</button>
              </div>
              <button class="print-coupons">
                <i class="fa-solid fa-print"></i> <br />Print <br />Coupons
              </button>
            </div>
          </div>
          <div class="coupons-container">
            <div class="coupons-left">
              <div class="coupons-call">
                <h3>Emergency Call 24/7 (716) 937-4628 or (585) 815-7658</h3>
              </div>
              <div class="coupons-left-body">
                <h3>Electrical Service Charge</h3>
                <p>
                  Print out this coupon and give it to one of our Technicians at
                  the time of your consultation to receive your discount. When you
                  have LJL Electrical, Heating & Cooling Inc. service your main
                  service electrical panel (service change over) you’ll know you
                  received “ The Best Price”
                </p>
                <button>Redeemable During Any Time</button>
              </div>
            </div>

            <div class="coupons-right">
              <div class="coupons-discount">
                <img src="/assets/images/logo.png" alt="logo" />
                <h3>$50</h3>
                <button>Off</button>
              </div>
              <button class="print-coupons">
                <i class="fa-solid fa-print"></i> <br />Print <br />Coupons
              </button>
            </div>
          </div>
          <div class="coupons-container">
            <div class="coupons-left">
              <div class="coupons-call">
                <h3>Emergency Call 24/7 (716) 937-4628 or (585) 815-7658</h3>
              </div>
              <div class="coupons-left-body">
                <h3>Home A/C Repairs</h3>
                <p>
                  If you’re dying of the heat, you’re A/C broke down call LJL Inc.
                  for a fast and reliable response to your air conditioning needs
                </p>
                <button>Redeemable During Any Time</button>
              </div>
            </div>

            <div class="coupons-right">
              <div class="coupons-discount">
                <img src="/assets/images/logo.png" alt="logo" />
                <h3>$50</h3>
                <button>Off</button>
              </div>
              <button class="print-coupons">
                <i class="fa-solid fa-print"></i> <br />Print <br />Coupons
              </button>
            </div>
          </div>
          <div class="coupons-container">
            <div class="coupons-left">
              <div class="coupons-call">
                <h3>Emergency Call 24/7 (716) 937-4628 or (585) 815-7658</h3>
              </div>
              <div class="coupons-left-body">
                <h3>A/C Install / Replacement</h3>
                <p>
                  If your existing A/C no longer works or your putting one in on
                  the top of a existing furnace, call LJL for a professional
                  install of your new A/C.
                </p>
                <button>Redeemable Between May to October 2024</button>
              </div>
            </div>

            <div class="coupons-right">
              <div class="coupons-discount">
                <img src="/assets/images/logo.png" alt="logo" />
                <h3>$50</h3>
                <button>Off</button>
              </div>
              <button class="print-coupons">
                <i class="fa-solid fa-print"></i> <br />Print <br />Coupons
              </button>
            </div>
          </div>
          <div class="coupons-container">
            <div class="coupons-left">
              <div class="coupons-call">
                <h3>Emergency Call 24/7 (716) 937-4628 or (585) 815-7658</h3>
              </div>
              <div class="coupons-left-body">
                <h3>Furnace Cleaning</h3>
                <p>
                  Don’t let your furnace get sick before you use it next year.
                  Take care of your furnace, so it can take care of you next
                  winter.
                </p>
                <button>Redeemable Between March-September 2024</button>
              </div>
            </div>

            <div class="coupons-right">
              <div class="coupons-discount">
                <img src="/assets/images/logo.png" alt="logo" />
                <h3>$100</h3>
                <button>Off</button>
              </div>
              <button class="print-coupons">
                <i class="fa-solid fa-print"></i> <br />Print <br />Coupons
              </button>
            </div>
          </div>
          <div class="coupons-container">
            <div class="coupons-left">
              <div class="coupons-call">
                <h3>Emergency Call 24/7 (716) 937-4628 or (585) 815-7658</h3>
              </div>
              <div class="coupons-left-body">
                <h3>Furnace Repairs</h3>
                <p>
                  If your left out in the cold, when your furnace broke down, call
                  LJL for fast and reliable reponse to your heating needs.
                </p>
                <button>Redeemable Between October 2024 to April 2025</button>
              </div>
            </div>

            <div class="coupons-right">
              <div class="coupons-discount">
                <img src="/assets/images/logo.png" alt="logo" />
                <h3>10%</h3>
                <button>Off</button>
              </div>
              <button class="print-coupons">
                <i class="fa-solid fa-print"></i> <br />Print <br />Coupons
              </button>
            </div>
          </div>
          <div class="coupons-container">
            <div class="coupons-left">
              <div class="coupons-call">
                <h3>Emergency Call 24/7 (716) 937-4628 or (585) 815-7658</h3>
              </div>
              <div class="coupons-left-body">
                <h3>Furnace Replacement</h3>
                <p>
                  If your furnace already has let you down this winter, or your
                  looking to replace it before the winter gets worse. Or simply
                  you want to get better fuel savings, buy a newer and more
                  efficient.
                </p>
                <button>Redeemable Between Feburary 2024 to January 2025</button>
              </div>
            </div>

            <div class="coupons-right">
              <div class="coupons-discount">
                <img src="/assets/images/logo.png" alt="logo" />
                <h3>$100</h3>
                <button>Off</button>
              </div>
              <button class="print-coupons">
                <i class="fa-solid fa-print"></i> <br />Print <br />Coupons
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Coupons