import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const Electrical = () => {

  const [activePage, setActivePage] = useState('service');

  useEffect(() => {
    let listGroupItem = Array.from(document.getElementsByClassName("list-group-item"));
    listGroupItem.forEach(i => {
      i.classList.remove("nav-active");
    });
    let activeID = document.getElementById(activePage);
    if (activeID) {
      activeID.classList.add("nav-active");
    }
  })

  return (
    <>
      <div className="ser-plumbing-img">
        <img src="../assets/images/img2.png" alt="" />
        <div className="ser-overlay"></div>
        <div className="ser-text-container">
          <p>Our Services/Electrical</p>
          <h2>Electrical Upgrades, Repairs And Rewiring</h2>
        </div>
      </div>

      <div className="ser-body">
        <div className="ser-container">
          <div className="ser-services">
            <Link to="/electrical">
              <div className="ser-electric serv ser-active">
                <p>Electrical</p>
                <img src="/assets/icon/elec_icon_w.png" alt="" />
              </div>
            </Link>

            <Link to="/heating">
              <div className="ser-heating serv">
                <p>Heating</p>
                <img src="/assets/icon/heating_icon.png" alt="" />
              </div>
            </Link>

            <Link to="/cooling">
              <div className="ser-cooling serv">
                <p>Cooling</p>
                <img src="/assets/icon/cooling_icon.png" alt="" />
              </div>
            </Link>

            <Link to="/plumbing">
              <div className="ser-plumbing serv">
                <p>Plumbing</p>
                <img src="/assets/icon/plumbing_icon.png" alt="" />
              </div>
            </Link>

            <Link to="/mini">
              <div className="ser-mini serv">
                <p>Mini Excavation</p>
                <img src="/assets/icon/exa_icon.png" alt="" />
              </div>
            </Link>
          </div>

          <div className="ser-contact">
            <h2>Contact Us</h2>
            <div id="quote"></div>
            <p>
              <i style={{ marginRight: 15 }} class="fa-solid fa-phone"></i> <a href="tel:7169374628">716-937-4628</a>
            </p>
            <p>
              <i style={{ marginRight: 15 }} class="fa-solid fa-envelope"></i>
              <a href="mailTo:ljl@ljlehvac.com">ljl@ljlehvac.com</a>
            </p>
          </div>

          <div className="ser-quote">
            <h2>Get A Quote</h2>
            <form action="#" method="post">
              <div className="ser-form-content">
                <div className="ser-form-name ser-form">
                  <label for="name">Name</label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Enter Your Name"
                  />
                </div>

                <div className="ser-form-number ser-form">
                  <label for="phone">Phone Number</label>
                  <input
                    type="number"
                    name="phone"
                    id="phone"
                    placeholder="Enter Your Phone Number"
                  />
                </div>

                <div className="ser-form-email ser-form">
                  <label for="email">E-mail</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Enter Your Email"
                  />
                </div>

                <div className="ser-form-message ser-form">
                  <label for="message">How can we help?</label>
                  <textarea
                    name="message"
                    id="message"
                    placeholder="Your Message"
                    rows="5"
                    cols="30"
                  ></textarea>
                </div>
                <div className="ser-btn">
                  <button type="submit">
                    <i style={{marginRight: 15}} class="fa-solid fa-envelope"></i> Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="ser-details">
          <h2>We Do Anything Electrical</h2>
          <p>
            LJL Electrical, Heating & Cooling Inc. lights up the way to a better,
            more efficient, and cost-saving electrical solution for your business
            and home. We are a full-service electrical contractor experienced in
            all areas of electrical installations and repairs, servicing Alden,
            Erie, Genesee, and Wyoming counties and surrounding areas. For over 26
            years, we put the safety and security of your family, customers, and
            employees first.
          </p>
          <br />
          <div className="ser-list">
            <div className="ser-list1">
              <ul>
                <li>New Construction Rewiring</li>
                <li>Existing Electrical Rewiring</li>
                <li>LED Lights</li>
                <li>Parking Lot Lights</li>
                <li>Emergency Lights</li>
                <li>Football Field Lights</li>
              </ul>
            </div>

            <div className="ser-list2">
              <ul>
                <li>Smoke Detector Installation</li>
                <li>Carbon Monoxide Detector Installation</li>
                <li>Safety Inspections</li>
                <li>Lighting Fixture Installations</li>
                <li>Fuse Box Replacements</li>
                <li>Whole House Surge Protection</li>
              </ul>
            </div>

            <div className="ser-list3">
              <ul>
                <li>Pool Rewiring</li>
                <li>Generators</li>
                <li>Panel upgrades</li>
                <li>Electrical Repairs</li>
                <li>And More</li>
              </ul>
            </div>
          </div>

          <div className="ser-img1-cont">
            <div className="ser-img1">
              <img src="../assets/images/ser_elect_1.jpg" alt="" />
            </div>
            <div className="ser-img1-details ser-img1-np">
              <h2>Generator</h2>
              <p>
                Automatic powered generators will keep your lights, appliances,
                air conditioner, and electronics on during a power outage. Standby
                generators are a reliable backup power source and can be installed
                and connected to your home’s natural gas line or liquid propane
                fuel source. The standby backup power generator systems are
                automatic with very little on your part to do. Once the system is
                installed, you can set the generator to turn itself on and off
                automatically. The only thing you have to do is call us to perform
                maintenance. We can size, deliver, install, and service your
                generators.
              </p>
            </div>
          </div>

          <div className="ser-img2-cont">
            <div className="ser-img2-details ser-img2-np">
              <h2>Pools, Hot Tubs & Spa</h2>
              <p>
                Swimming pools are a great investment. It is a great way to cool
                off during the hot summers and have a fantastic place to spend
                with friends. They also require many electrical components and a
                company that is experienced in wiring to avoid dangerous
                situations. Our team can come out and install the wiring and
                ensure your equipment performs safely.
              </p>
            </div>
            <div className="ser-img2">
              <img src="../assets/images/1.2.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Electrical
