import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {

    useEffect(() => {
        const handleMenuToggle = () => {
            const checkBtn = document.getElementById('check');
            const links = document.querySelector('.links');
            if (checkBtn && links) {
                if (checkBtn.checked) {
                    links.style.left = '0';
                } else {
                    links.style.left = '-100%';
                }
            }
        };

        const handleQuoteButtonClick = () => {
            const links = document.querySelector('.links');
            const checkBtn = document.getElementById('check');
            if (links && checkBtn) {
                links.style.left = '-100%';
                checkBtn.checked = false;
            }
        };

        const servicesDropdown = document.getElementById("servicesDropdown");
        const chevronIcon = servicesDropdown ? servicesDropdown.querySelector(".fa-chevron-down") : null;

        const handleDropdownHover = () => {
            if (chevronIcon) {
                chevronIcon.classList.remove("fa-chevron-down");
                chevronIcon.classList.add("fa-chevron-up");
            }
        };

        const handleDropdownLeave = () => {
            if (chevronIcon) {
                chevronIcon.classList.remove("fa-chevron-up");
                chevronIcon.classList.add("fa-chevron-down");
            }
        };

        const checkBtn = document.getElementById('check');
        const quoteButton = document.getElementById('quote_button');

        if (checkBtn) {
            checkBtn.addEventListener('change', handleMenuToggle);
        }

        if (quoteButton) {
            quoteButton.addEventListener('click', handleQuoteButtonClick);
        }

        if (servicesDropdown) {
            servicesDropdown.addEventListener('mouseover', handleDropdownHover);
            servicesDropdown.addEventListener('mouseout', handleDropdownLeave);
        }

        return () => {
            // Cleanup event listeners
            if (checkBtn) {
                checkBtn.removeEventListener('change', handleMenuToggle);
            }

            if (quoteButton) {
                quoteButton.removeEventListener('click', handleQuoteButtonClick);
            }

            if (servicesDropdown) {
                servicesDropdown.removeEventListener('mouseover', handleDropdownHover);
                servicesDropdown.removeEventListener('mouseout', handleDropdownLeave);
            }
        };
    }, []);

    return (
        <>
            <nav className="nav" id="navbar">
                <Link to={'/'} className="logo">
                    <img src="../assets/images/logo.png" alt="" />
                    <p>Electrical, Heating and Cooling Inc.</p>
                </Link>
                <div className="links">
                    <Link to="/" className='list-group-item nav-active' id='Homepage'>Home</Link>
                    <div className="dropdown" id="servicesDropdown">
                        <Link to="/electrical" id='service' className='list-group-item' >Our Services <i className="fa-solid fa-chevron-down"></i></Link>

                        <div className="dropdown-content">
                            <a href={'/electrical'} className='list-group-item' id='electrical'>Electrical</a>
                            <a href={'/heating'} className='list-group-item' id='heating'>Heating</a>
                            <a href={'/cooling'} className='list-group-item' id='cooling'>Cooling</a>
                            <a href={'/plumbing'} className='list-group-item' id='plumbing'>Plumbing</a>
                            <a href={'/mini'} className='list-group-item' id='mini'>Mini Excavation</a>
                        </div>
                    </div>
                    <Link to="/contact" className='list-group-item' id='contact'>Contact Us</Link>
                    <Link to="/coupons" className='list-group-item' id='coupons'>Coupons</Link>
                    <div className="button quote-btn1">
                        <a href="#quote"><button>Get A Quote</button></a>
                    </div>
                </div>
                <div className="button quote-btn">
                    <a href="#quote"><button>Get A Quote</button></a>
                </div>

                <input type="checkbox" id="check" className="checkbtn" />
                <label htmlFor="check" className="checkbtn-label">
                    <i className="fa-solid fa-bars"></i>
                </label>
            </nav>
        </>
    );
}

export default Navbar;
