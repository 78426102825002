import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const Mini_excavation = () => {

  const [activePage, setActivePage] = useState('service');

  useEffect(() => {
    let listGroupItem = Array.from(document.getElementsByClassName("list-group-item"));
    listGroupItem.forEach(i => {
      i.classList.remove("nav-active");
    });
    let activeID = document.getElementById(activePage);
    if (activeID) {
      activeID.classList.add("nav-active");
    }
  })
  return (
    <>
      <div className="ser-plumbing-img">
        <img src="../assets/images/img3.png" alt="" />
        <div className="ser-overlay"></div>
        <div className="ser-text-container">
          <p>Our Services/ Mini Excavation</p>
          <h2>We Dig Ditches & Trenches</h2>
        </div>
      </div>
      {/* <!-- body part starts here  --> */}
      <div className="ser-body">
        <div className="ser-container">

          <div className="ser-services">
            <Link to="/electrical">
              <div className="ser-electric serv">
                <p>Electrical</p>
                <img src="/assets/icon/elec_icon.png" alt="" />
              </div>
            </Link>

            <Link to="/heating">
              <div className="ser-heating serv">
                <p>Heating</p>
                <img src="/assets/icon/heating_icon.png" alt="" />
              </div>
            </Link>

            <Link to="/cooling">
              <div className="ser-cooling serv">
                <p>Cooling</p>
                <img src="/assets/icon/cooling_icon.png" alt="" />
              </div>
            </Link>

            <Link to="/plumbing">
              <div className="ser-plumbing serv">
                <p>Plumbing</p>
                <img src="/assets/icon/plumbing_icon.png" alt="" />
              </div>
            </Link>

            <Link to="/mini">
              <div className="ser-mini serv ser-active">
                <p>Mini Excavation</p>
                <img src="/assets/icon/exa_icon_w.png" alt="" />
              </div>
            </Link>
          </div>

          <div className="ser-contact">
            <h2>Contact Us</h2>
            <div id="quote"></div>
            <p><i style={{marginRight: 15}} class="fa-solid fa-phone"></i> <a href="tel:7169374628">716-937-4628</a></p>
            <p><i style={{marginRight: 15}} class="fa-solid fa-envelope"></i> <a href="mailTo:ljl@ljlehvac.com">ljl@ljlehvac.com</a></p>
          </div>

          <div className="ser-quote">
            <h2>Get A Quote</h2>
            <form action="#" method="post">
              <div className="ser-form-content">
                <div className="ser-form-name ser-form">
                  <label for="name">Name</label>
                  <input type="text" name="name" id="name" placeholder="Enter Your Name" />
                </div>

                <div className="ser-form-number ser-form">
                  <label for="phone">Phone Number</label>
                  <input type="number" name="phone" id="phone" placeholder="Enter Your Phone Number" />
                </div>

                <div className="ser-form-email ser-form">
                  <label for="email">E-mail</label>
                  <input type="email" name="email" id="email" placeholder="Enter Your Email" />
                </div>

                <div className="ser-form-message ser-form">
                  <label for="message">How can we help?</label>
                  <textarea name="message" id="message" placeholder="Your Message" rows="5" cols="30"></textarea>
                </div>
                <div className="ser-btn">
                  <button type="submit"><i style={{marginRight: 15}} class="fa-solid fa-envelope"></i> Submit</button>
                </div>
              </div>
            </form>
          </div>

        </div>

        <div className="ser-details">
          <h2>Excavation Services</h2>
          <p>
            LJL Electrical, Heating & Cooling Inc. servicing Alden, Erie, Genesee, and Wyoming counties and surrounding areas can prepare your land for water lines, electrical lines, well systems, gas lines, water wells, pool wiring and gas lines for heaters. We offer a variety of mini-excavation jobs at the best prices. Our equipment is top of the lines and our team is cautious and dependable when working on any service.
            <br /> <br />
            Give us a call at <a href="tel:7169374628">716-937-4628</a> to schedule your excavation service.
          </p>
          <br />


          <div className="ser-img1-cont">
            <div className="ser-img1">
              <img src="../assets/images/mini_1.png" alt="" />
            </div>
            <div className="ser-img1-details ser-mini">
              <ul>
                <p>Water Lines</p>
                <li>Main water from the street to your house</li>
                <li>From one house to another building</li>
              </ul>
              <ul>
                <p>Electrical Lines</p>
                <li>Main service lines underground from pole to house</li>
                <li>Wires feeding pools, hot tubs, garages</li>
                <li>From one house to another building</li>
              </ul>
            </div>
          </div>

          <div className="ser-img2-cont">
            <div className="ser-img2-details ser-mini">
              <ul>
                <p>Well Systems</p>
                <li>From well casing to the inside of the basement</li>
                <li>From one house to another building, such as barns, or other buildings on the property</li>
              </ul>
              <ul>
                <p>Gas Lines</p>
                <li>From the main street to your house</li>
                <li>From one house to another building</li>
                <li>Pool heaters away from your house</li>
                <li>Buildings away from your house</li>
              </ul>
            </div>
            <div className="ser-img2">
              <img src="../assets/images/2.png" alt="" />
            </div>
          </div>

          <div className="ser-img1-cont">
            <div className="ser-img1">
              <img src="../assets/images/mini_3.jpg" alt="" />
            </div>
            <div className="ser-img1-details ser-mini">
              <ul>
                <p>Septic Lines</p>
                <li>From your house to septic tank</li>
                <li>From tank to distribution box</li>
                <li>From distribution box to leach lines</li>
              </ul>

            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default Mini_excavation
