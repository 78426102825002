import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

const Heating = () => {

  const [activePage, setActivePage] = useState('service');

  useEffect(() => {
    let listGroupItem = Array.from(document.getElementsByClassName("list-group-item"));
    listGroupItem.forEach(i => {
      i.classList.remove("nav-active");
    });
    let activeID = document.getElementById(activePage);
    if (activeID) {
      activeID.classList.add("nav-active");
    }
  })


  return (
    <>
      <div className="ser-plumbing-img">
      <img src="../assets/images/Heating banner.png" alt="" />
      <div className="ser-overlay"></div>
      <div className="ser-text-container">
        <p>Our Services/Heating</p>
        <h2>Helping You Stay Warm During The Cold, Winter Months</h2>
      </div>
    </div>
    {/* <!-- body part starts here  --> */}
    <div className="ser-body">
      <div className="ser-container">
      <div className="ser-services">
            <Link to="/electrical">
              <div className="ser-electric serv">
                <p>Electrical</p>
                <img src="/assets/icon/elec_icon.png" alt="" />
              </div>
            </Link>

            <Link to="/heating">
              <div className="ser-heating serv ser-active">
                <p>Heating</p>
                <img src="/assets/icon/heating_icon_w.png" alt="" />
              </div>
            </Link>

            <Link to="/cooling">
              <div className="ser-cooling serv">
                <p>Cooling</p>
                <img src="/assets/icon/cooling_icon.png" alt="" />
              </div>
            </Link>

            <Link to="/plumbing">
              <div className="ser-plumbing serv">
                <p>Plumbing</p>
                <img src="/assets/icon/plumbing_icon.png" alt="" />
              </div>
            </Link>

            <Link to="/mini">
              <div className="ser-mini serv">
                <p>Mini Excavation</p>
                <img src="/assets/icon/exa_icon.png" alt="" />
              </div>
            </Link>
          </div>

        <div className="ser-contact">
          <h2>Contact Us</h2>
          <div id="quote"></div>
          <p>
            <i style={{marginRight: 15}} class="fa-solid fa-phone"></i> <a href="tel:7169374628">716-937-4628</a>
          </p>
          <p>
            <i style={{marginRight: 15}} class="fa-solid fa-envelope"></i>
            <a href="mailTo:ljl@ljlehvac.com">ljl@ljlehvac.com</a>
          </p>
        </div>

        <div className="ser-quote">
          <h2>Get A Quote</h2>
          <form action="#" method="post">
            <div className="ser-form-content">
              <div className="ser-form-name ser-form">
                <label for="name">Name</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Enter Your Name"
                />
              </div>

              <div className="ser-form-number ser-form">
                <label for="phone">Phone Number</label>
                <input
                  type="number"
                  name="phone"
                  id="phone"
                  placeholder="Enter Your Phone Number"
                />
              </div>

              <div className="ser-form-email ser-form">
                <label for="email">E-mail</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Enter Your Email"
                />
              </div>

              <div className="ser-form-message ser-form">
                <label for="message">How can we help?</label>
                <textarea
                  name="message"
                  id="message"
                  placeholder="Your Message"
                  rows="5"
                  cols="30"
                ></textarea>
              </div>
              <div className="ser-btn">
                <button type="submit">
                  <i style={{marginRight: 15}} class="fa-solid fa-envelope"></i> Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="ser-details">
        <h2>Heating Service</h2>
        <p>
          We serve the Alden, Erie, Genesee, and Wyoming counties and
          surrounding areas. Our team gets the job done and offers an efficient
          and affordable solution to your heating issues. No matter what heating
          service you need, from repairs to installation and maintenance, our
          technicians handle your heating service for residents and business
          owners.
        </p>
        <br />
        <div className="ser-list">
          <div className="ser-list1">
            <ul>
              <li>Furnaces</li>
              <li>Heating System</li>
            </ul>
          </div>

          <div className="ser-list2">
            <ul>
              <li>Boilers</li>
              <li>Heating Pumps</li>
            </ul>
          </div>

          <div className="ser-list3">
            <ul>
              <li>Water Tank Heaters</li>
            </ul>
          </div>
        </div>

        <div className="ser-img1-cont">
          <div className="ser-img1">
            <img src="../assets/images/f1.jpg" alt="" />
          </div>
          <div className="ser-img1-details ser-img1-np">
            <h2>Residential</h2>
            <p>
              We can help you choose the right heating system for your home and
              your budget. Once we find the heater you need to keep your family
              warm, we install the system and provide continued maintenance
              services to keep it running longer. Nothing is more stressful than
              coming home to find out your heater broke during the freezing
              cold. Our technicians go through training and can provide heating
              repairs at the best prices in the area. We go the extra mile to
              ensure your heating installation or repairs are done the right
              way.
            </p>
          </div>
        </div>

        <div className="ser-img2-cont">
          <div className="ser-img2-details ser-img2-np">
            <h2>Commercial</h2>
            <p>
              Furnaces heat and circulate warm air in the winter. Furnace
              heating ability is measured with an Annual Fuel Utilization
              Efficiency (AFUE) percentage. A higher AFUE percentage indicates a
              more efficient furnace.
            </p>
          </div>
          <div className="ser-img2">
            <img src="../assets/images/heating_2.png" alt="" />
          </div>
        </div>

        <div className="ser-mid-cont">
          <h2>
            Offering Effective Commercial Heating Solutions At The Best Rates
          </h2>
          <p>
            We want you to have a stress-free experience with our team. We
            understand that your property is important and that is why we
            conduct a thorough assessment to identify the needs of your HVAC
            system. Our team is available to answer questions or hear out your
            concerns. We offer a full, multi-year warranty on all the parts and
            labor we provide and will only leave your premises when you are 100%
            satisfied with the work. Our team handles urgent matters with our
            24/7 emergency dispatch service for issues that need to be rectified
            immediately. We specialize in designing your heating system for all
            business types. Our team comes up with a custom solution to fit your
            business’ budget and size. We offer emergency services to avoid any
            downtime to your business. If your heater stops working, we offer
            commercial heating repair services as well as maintenance.
          </p>
        </div>

        <div className="ser-img1-cont">
          <div className="ser-img1">
            <img src="../assets/images/heating3.gif" alt="" />
          </div>
          <div className="ser-img1-details ser-img1-np">
            <h2>Your Heating Service Is Our Priority</h2>
            <p>
              Our service department treats each maintenance call or emergency
              visit as an opportunity to improve comfort while cutting energy
              costs for our clients. Our service team and engineering department
              try to be familiar with each client’s facility so we can take a
              proactive approach to energy-saving opportunities. Call us today
              at <a href="tel:7169374628">716-937-4628</a> for an estimate.
            </p>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Heating